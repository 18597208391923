<template>
  <div class="md:w-5/12 lg:w-4/12 xl:w-3/12 mx-auto py-16">
    <div class="flex items-center justify-between mb-12">
      <BackButton @onClick="back"/>
      <Steps/>
    </div>
    <h2 class="font-bold text-4xl mb-20">Let us know about your business...</h2>
    <ValidationObserver v-slot="{ invalid }" rules="required">
      <form @submit.prevent="createChurch" class="flex flex-col space-y-8">
        <ValidationProvider name="Business logo" rules="required" v-slot="{ errors }" ref="churchLogo">
          <input type="hidden" v-model="church.church_logo_url">
          <div>
            <InputLabel label="Your business logo"/>
            <ImageUploadSingle
              entity="new-church"
              :imageUrl="churchLogoUrl"
              @uploaded="churchLogoUpdated"
              @deleted="churchLogoUpdated(null)"
            />
          </div>
          <p class="text-functional-error text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
        </ValidationProvider>

        <ValidationProvider name="Cover image" v-slot="{ errors }" ref="coverImage">
          <div>
            <InputLabel label="Cover image"/>
            <ImageUploadSingle
              entity="new-church"
              :imageUrl="coverImageUrl"
              @uploaded="coverImageUpdated"
              @deleted="coverImageUpdated(null)"
              cropStencilComponent="rectangle-stencil"
              :cropAspectRatio="12/6"
            />
          </div>
          <p class="text-functional-error text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
        </ValidationProvider>

        <ValidationProvider name="title" rules="required" v-slot="{ errors }">
          <TextInput
            v-model="form.user.title"
            label="What is your title?"
            x-padding="px-5"
            placeholder="E.g. Assistant pastor"
            :errors="errors"
          />
        </ValidationProvider>

        <ValidationProvider name="Business name" rules="required" v-slot="{ errors }">
          <TextInput
            v-model="church.name"
            label="Business name"
            x-padding="px-5"
            :errors="errors"
          />
        </ValidationProvider>

        <ValidationProvider name="Business phone" rules="required" v-slot="{ errors }">
          <TextInput
            v-model="churchPhone"
            label="Phone"
            x-padding="px-5"
            :errors="errors"
            :phoneInput="true"
            @phone="setPhone($event)"
          />
        </ValidationProvider>

        <BaseMultiselect :options="$constants.CHURCHES.COUNTRIES" trackBy="code" label="Country" :multiple="false" type="country" @selected="setChurchCountry" />

        <ValidationProvider name="State" rules="required" v-slot="{ errors }">
          <TextInput
            v-model="church.state"
            label="State/province"
            x-padding="px-5"
            placeholder="Florida"
            :errors="errors"
          />
        </ValidationProvider>

        <ValidationProvider name="City" rules="required" v-slot="{ errors }">
          <TextInput
            v-model="church.city"
            label="City"
            x-padding="px-5"
            placeholder="Miami"
            :errors="errors"
          />
        </ValidationProvider>

        <div class="col-span-2 md:col-span-1">
          <button type="submit" :disabled="invalid">
            <ButtonIcon text="Create account" :disabled="invalid" :loading="loading"/>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import {mapMutations, mapState, mapActions} from "vuex";
import BackButton from "@/components/shared/buttons/BackButton";
import Steps from "@/components/register/Steps";
import TextInput from "@/components/shared/inputs/TextInput";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import BaseMultiselect from "@/components/shared/inputs/BaseMultiselect";
import ImageUploadSingle from '@/components/shared/ImageUploadSingle';
import InputLabel from "@/components/shared/labels/InputLabel";

export default {
  name: "ChurchForm",
  components: {
    ButtonIcon,
    TextInput,
    Steps,
    BackButton,
    BaseMultiselect,
    ImageUploadSingle,
    InputLabel
  },

  data() {
    return {
      churchLogoUrl: null,
      coverImageUrl: null,
      loading: false,
      church: {
        church_logo_url: '',
        cover_image_url: null,
        name: '',
        phone: '',
        phone_country_code: '',
        state: '',
        city: '',
        country: null,
      },
      churchPhone: null
    }
  },

  mounted() {
    if (this.type != 'church') {
      this.back();
    }
  },
  methods: {
    ...mapActions({
      register: 'auth/register',
    }),

    ...mapMutations({
      decreaseStep: 'auth/decreaseStep',
    }),

    back() {
      this.$router.back();
      this.decreaseStep();
    },

    setPhone(phoneObject) {
      this.church.phone_country_code = phoneObject.countryCode;
      this.church.phone = phoneObject.number;
    },

    async createChurch(){
      await this.$refs.churchLogo.validate();
      await this.$refs.coverImage.validate();
      this.loading = true;
      this.form.church = this.church
      this.register(this.form)
        .then(response => {
          if (response) {
            window.fbq('trackCustom', 'signup-church');
            this.$router.push({name:'Home'});
          }
        })
        .catch(errors => {
          console.log(errors)
          this.$refs.userForm.setErrors(errors.errors)
        })
        .finally(() => this.loading = false)
    },

    churchLogoUpdated(url) {
      this.churchLogoUrl = url;
      this.setChurchLogoUrl(url)
      this.$nextTick(() => {
        this.$refs.churchLogo.validate();
      });
    },

    coverImageUpdated(url) {
      this.coverImageUrl = url;
      this.setChurchCoverImage(url)
      this.$nextTick(() => {
        this.$refs.coverImage.validate();
      });
    },

    setChurchCountry(country) {
      if (isEmpty(country)) {
        delete this.church.country;
      } else {
        this.church.country = country.code;
      }
    },

    setChurchLogoUrl(url) {
      this.church.church_logo_url = url
    },

    setChurchCoverImage(url) {
      this.church.cover_image_url = url
    },
  },

  computed: {
    ...mapState({
      form: state => state.auth.form,
      type: state => state.auth.type,
    }),
  },
}
</script>

